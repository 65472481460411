import React, { Fragment } from 'react';
import { List, Filter, Datagrid, TextInput, TextField, BulkDeleteButton, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const ActionButtons = ({ basePath, record, resource }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} record={record} />
        <ExportButton />
    </TopToolbar>
);

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false}/>
    </Fragment>
);

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput  source="display_name" alwaysOn />
    </Filter>
);

const StatusTextFieldUserAccess = ({ record, source }) => (
    <span className={(record.access.user)? 'text-success':'text-danger'}>{(record.access.user)? <CheckIcon/>: <ClearIcon/>}</span>
)

const StatusTextFieldAdminAccess = ({ record, source }) => (
    <span className={(record.access.admin)? 'text-success':'text-danger'}>{(record.access.admin)? <CheckIcon/>: <ClearIcon/>}</span>
)

const StatusTextField = ({ record, source }) => (
    <span className={(record[source])? 'text-success':'text-danger'}><CircleIcon/></span>
)

export const UserList = props => (
    <List {...props} filters={<UserFilter />} bulkActionButtons={<BulkActionButtons />} actions={<ActionButtons />}>
        <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
            <TextField source="display_name" label="Name"/>
            <TextField source="email" label="Email"/>
            <StatusTextFieldUserAccess record={{...props}} source="access.user" label="User Access"/>
            <StatusTextFieldAdminAccess record={{...props}} source="access.admin" label="Admin Access"/>
            <StatusTextField source="disabled" label="Disabled"/>
        </Datagrid>
    </List>
);

export default UserList;