import InspectionList from './InspectionList';
import InspectionShow from './InspectionShow';
import Icon from '@material-ui/icons/AllOut';

const ops = {
    list: InspectionList,
    show: InspectionShow,
    icon: Icon
};

export default ops;