import * as React from "react";
import PropTypes from 'prop-types';
import ShowFormattedDate from './ShowFormattedDate';

const CustomDateField = ({ source, record = {} }) => {
    return (
        <ShowFormattedDate date={record[source]} />
    );
}

CustomDateField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    label: PropTypes.string
};

export default CustomDateField;