import React, { useState } from 'react';
import { required, Button, SaveButton, DateInput, SelectInput, ReferenceInput, useCreate, useNotify, FormWithRedirect } from 'react-admin';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SystemItemsForInspectionForm from './SystemItemsForInspectionForm';
import DateFormatter from '../components/DateFormatter';

function InspectionCreateModal({ record, onChange }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('inspections');
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = system_id =>
        async values => {
            var {
                date,
                inspector_name,
                inspector_id,
                inspected
            } = values;

            create(
                {
                    payload: {
                        data: {
                            location_id: record.location_id,
                            system_id: system_id,
                            date: date, //console.log(`${date.replace('/', '-').split('-')[2]+'-'+date.replace('/', '-').split('-')[1]+'-'+date.replace('/', '-').split('-')[0]}`)
                            inspector_name: inspector_name,
                            inspector_id: inspector_id,
                            inspected: inspected
                        }
                    }
                },
                {
                    onSuccess: () => {
                        setShowDialog(false);
                        onChange();
                        notify('Inspection created', 'info', {}, false);
                    },
                    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
                }
            );
        };


    return (
        <>
            <Button className="btn btn-light btn-sm text-danger m-0 p-0 mb-1" style={{fontSize: '0.80em'}} onClick={handleClick} label="Add">
                <AddIcon className="m-0"/>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create inspection"
            >
                <DialogTitle>
                    <Grid container direction="row" justify="content-between" className="m-0 p-0">
                        <h4 className="p-0 text-danger" style={{marginLeft: '-8px'}}>Create Inspection</h4>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <CloseIcon item
                                onClick={handleCloseClick}
                                disabled={loading}
                                style={{ cursor: "pointer", color: "gray" }}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>

                <FormWithRedirect
                    resource="inspections"
                    save={handleSubmit(record.id)}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <div>
                            <DialogContent className="m-2 p-2">
                                <Grid container direction="row" spacing={2}>
                                    <Grid className="my-0" item xs={12} sm={6} className="pt-2">
                                        <DateInput
                                            className="my-0 w-100"
                                            source="date"
                                            validate={required()}
                                            initialValue={new Date()}
                                            format={DateFormatter}
                                            variant="outlined"
                                            label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Date</h6>}
                                        />
                                    </Grid>
                                    <Grid className="my-0" item xs={12} sm={6} className="pt-2">
                                        <ReferenceInput
                                            className="my-0 w-100"
                                            label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Inspector</h6>}
                                            source="inspector_id"
                                            reference="inspectors"
                                            validate={required()}
                                            variant="outlined"
                                        >
                                            <SelectInput optionText="display_name" />
                                        </ReferenceInput>
                                    </Grid>
                                </Grid>

                                <SystemItemsForInspectionForm record={record.elements}/>
                            </DialogContent>

                            <DialogActions>
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    justify="start"
                                    alignItems="center"
                                    className="p-2"
                                >
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </Grid>

                            </DialogActions>

                        </div>
                    )}
                />
            </Dialog>
        </>
    );
}

export default InspectionCreateModal;

