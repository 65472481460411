import { fetchUtils } from "react-admin";
import { FirebaseAuthProvider } from 'react-admin-firebase';
import { firebaseConfig } from '../config/firebase';

const firebaseOptions = {
    logging: false,
}

export const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    let token = await getToken();
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);

}

async function getToken() {
    const firebaseAuthProvider = FirebaseAuthProvider(firebaseConfig, firebaseOptions);
    return firebaseAuthProvider.checkAuth().then((result) => result._lat);
}