import React, { useState } from 'react';
import { Button, SaveButton, useCreate, useNotify, FormWithRedirect } from 'react-admin';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SystemCreateForm from './SystemCreateForm'

function SystemCreateModal({ record, onChange }) {

    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('systems');
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = location_id =>
        async values => {
            var {
                name,
                code,
                type,
                elements
            } = values;

            var elementsaux = {}

            for (const key in elements) {
                elementsaux[key] = {}
                let frequencies = []
                elements[key].inspections.forEach(frequency => {
                    switch (frequency) {
                        case 'monthly':
                            frequencies.push({ monthly: {} })
                            break;
                        case 'quarterly':
                            frequencies.push({ quarterly: {} })
                            break;
                        case 'annual':
                            frequencies.push({ annual: {} })
                            break;
                        case 'biannual':
                            frequencies.push({ biannual: {} })
                            break;
                        case 'fiveyears':
                            frequencies.push({ fiveyears: {} })
                            break;
                        default: ; break;
                    }
                });

                var frequenciesaux = {};
                for (var i = 0; i < frequencies.length; i++) {
                    for (const key in frequencies[i]) {
                        frequenciesaux[key] = {}
                    }
                }

                elementsaux[key].inspections = frequenciesaux
            }

            if (elementsaux.main == undefined) {
                elementsaux['main'] = {
                    "inspections": {
                        "monthly": {}
                    }
                }
            }

            create(
                {
                    payload: {
                        data: {
                            "location_id": location_id,
                            "name": name,
                            "code": code,
                            "type": type,
                            "status": "Active",
                            "elements": elementsaux
                        }
                    }
                },
                {
                    onSuccess: () => {
                        setShowDialog(false);
                        onChange();
                        notify('System created', 'info', {}, false);
                    },
                    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
                }

            );
        };

    return (
        <>
            <Button className="btn btn-light btn-sm text-danger m-0 p-0 mb-1" style={{ fontSize: '0.80em' }} onClick={handleClick} label="Add">
                <AddIcon className="m-0" />
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create system"
            >
                <DialogTitle>
                    <Grid container direction="row" justify="content-between" className="m-0 p-0">
                        <h4 className="p-0 text-danger" style={{ marginLeft: '-8px' }}>Create System</h4>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <CloseIcon item
                                onClick={handleCloseClick}
                                disabled={loading}
                                style={{ cursor: "pointer", color: "gray" }}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>

                <FormWithRedirect
                    resource="systems"
                    save={handleSubmit(record.id)}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <div>
                            <DialogContent className="m-2 p-2">
                                <SystemCreateForm />
                            </DialogContent>

                            <DialogActions>
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    justify="start"
                                    alignItems="center"
                                    className="p-2"
                                >
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        redirect='systems'
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </Grid>
                            </DialogActions>
                        </div>
                    )}
                />
            </Dialog>
        </>
    );
}

export default SystemCreateModal;

