import ISConfig from '../config';
import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import CustomerEdit from './CustomerEdit';
import CustomerCreate from './CustomerCreate';
import BusinessIcon from '@material-ui/icons/BusinessCenter';

const ops = {
    list: CustomerList,
    show: CustomerShow,
    edit: CustomerEdit,
    icon: BusinessIcon
};

if (!ISConfig.qbi) {
    ops.create = CustomerCreate
}

export default ops
