import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import ISConfig from '../config';
import { useField } from 'react-final-form';
import { TextInput, required, maxLength, number, regex, minLength } from 'react-admin';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

const CustomAutocompleteStreetInput = ({ source }) => {
    const {
        input: { name, onChange, value },
        meta: { touched, error }
    } = useField(source);

    const classes = useStyles();
    const [v, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [cityAddress, setCityAddress] = React.useState('');
    const [stateAddress, setStateAddress] = React.useState('');
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${ISConfig.googleAPIkey}&libraries=places&language=en`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(v ? [v] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (v) {
                    newOptions = [v];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions.filter(element => (element.structured_formatting.secondary_text != undefined || element.structured_formatting.secondary_text != null) ? element.structured_formatting.secondary_text.includes('NY') : ''));
            }
        });

        return () => {
            active = false;
        };
    }, [v, inputValue, fetch]);

    return (
        <>
            <div className="py-0 my-0">
                <Autocomplete
                    id="google-map-demo"
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        
                        if (newValue) {
                            onChange(newValue.structured_formatting.main_text);
                            setStateAddress(newValue.terms.filter(element => element.value.length == 2)[0].value);
                            setCityAddress(newValue.terms[newValue.terms.length - 3].value);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => {
                        return (
                            <TextInput
                                {...params}
                                source={source}
                                label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Street</h6>}
                                onChange={onChange}
                                validate={required()}
                                variant="outlined"
                            />
                        )
                    }}
                    renderOption={(option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            </div>
            <div className="row my-0 py-0">
                <div className="col-6 col-md-8">
                    <CustomAutocompleteInput source="address.city" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>City</h6>} setvalue={cityAddress} validate={required()} variant="outlined"/>
                </div>
                <div className="col-3 col-md-2">
                    <CustomAutocompleteInput source="address.state" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>State</h6>} setvalue={stateAddress} validate={[required(), minLength(2), maxLength(2)]} variant="outlined"/>
                </div>
                <div className="col-3 col-md-2">
                    <TextInput source="address.zip" validate={[required(), regex(/^\d{5}$/, 'Must be a valid Zip Code')]} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Zip</h6>} fullWidth variant="outlined"/>
                </div>
            </div>
        </>
    );
}

const CustomAutocompleteInput = ({ source, label, setvalue, validate, variant }) => {
    const {
        input: { name, onChange, value },
        meta: { touched, error }
    } = useField(source);

    return (
        <TextInput
            source={source}
            label={label}
            value={setvalue}
            onChange={onChange(setvalue || value)}
            validate={validate}
            variant={variant}
            fullWidth
        />
    )
}

const CustomAddressInput = ({ record }) => {
    return (
        <CustomAutocompleteStreetInput source="address.street" />
    );
};

export default CustomAddressInput;