import React from 'react';
import { SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CreateDialog } from '@react-admin/ra-form-layout';
import CustomAddressInput from '../components/CustomAddressInput';

const LocationCreate = (props) => {
    return (
        <CreateDialog {...props} fullWidth maxWidth="md" title={<h4 className="p-0 text-danger" style={{ marginLeft: '-8px' }}>Create Location</h4>}>
            <SimpleForm fullWidth initialValues={{ status: "Active", contact: { display_name: '', email: '', phone: '', notes: '' } }} redirect="show">
                <div className="w-100">
                    <div className="row row-cols-2 px-2">
                        <div>
                            <TextInput source="name" validate={required()} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} variant="outlined" fullWidth />
                        </div>
                        <div>
                            <ReferenceInput source="customer_id" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Customer</h6>} resource="customers" reference="customers" filterToQuery={searchText => ({ name: searchText })}>
                                <AutocompleteInput optionText="name" validate={required()} fullWidth variant="outlined" />
                            </ReferenceInput>
                        </div>
                    </div>

                    <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
                        <div className="rounded" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                            <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes" />
                        </div>
                    </div>

                    <h6 className="font-weight-normal px-2 pt-2">Address</h6>
                    <div className="px-2 w-100">
                        <CustomAddressInput record={{ ...props }} />
                    </div>

                    <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                    <div className="row row-cols-3 g-2 g-lg-3 px-2">
                        <div>
                            <TextInput source="contact.display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} variant="outlined" fullWidth />
                        </div>
                        <div>
                            <TextInput source="contact.phone" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Phone</h6>} variant="outlined" fullWidth />
                        </div>
                        <div>
                            <TextInput source="contact.email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" fullWidth />
                        </div>
                    </div>
                    <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
                        <div className="rounded" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                            <RichTextInput source="contact.notes" label="Notes" stripTags emptyText="Notes" />
                        </div>
                    </div>
                </div>

            </SimpleForm>
        </CreateDialog>
    )
}

export default LocationCreate;