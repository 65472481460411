import React from 'react';
import { Show, ReferenceField, SimpleShowLayout } from 'react-admin';
import BackButton from '../components/BackButton';
import CustomTextField from '../components/CustomTextField';
import ShowFormattedDate from '../components/ShowFormattedDate';
import InspectionTitle from "./InspectionTitle";
import CardActions from "@material-ui/core/CardActions";

const InspectionShowActions = (props) => {
    return (
        <CardActions>
            <BackButton style={{ marginRight: "auto" }}></BackButton>
        </CardActions>
    );
};

const InspectionShowGeneral = ({ record }) => {
    const { date } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>System Name</h6>
                <ReferenceField style={{ margin: '0px' }} label="" source="system_id" resource="systems" reference="systems" link="show"><CustomTextField className="m-0 p-0" source="name" /></ReferenceField>
            </div>
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>Date</h6>
                <ShowFormattedDate date={date} />
            </div>
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>Inspector</h6>
                <ReferenceField style={{ margin: '0px' }} label="" source="inspector_id" resource="inspectors" reference="inspectors" link="show"><CustomTextField className="m-0 p-0" source="display_name" /></ReferenceField>
            </div>
        </div>
    );
};

const InspectionShowElements = ({ record }) => {
    const { inspected } = record;

    var elementskey = [];
    for (const key in inspected) {

        var fs = '';
        inspected[key].forEach(f => {
            fs += f.charAt(0).toUpperCase() + f.slice(1) + ', ';
        });
        fs = fs.substring(0, fs.length - 2);

        elementskey.push(
            <div className="mt-0 pt-0" id={key.replace(' ', '') + '-div'}>
                <span  className="fw-bold" style={{fontSize: '24px'}}>{key.charAt(0).toUpperCase() + key.slice(1)}: </span><span style={{fontSize: '16px', fontStyle: 'none'}} className="small text-secondary fw-thin">{fs}</span>
            </div>
        );
    }

    return (
        <div>
            <div className="mt-0 pt-0">
                {elementskey.filter(element => element.props.id == "main-div")[0]}
                {elementskey.filter(element => element.props.id == 'antifreeze-div')[0]}
                {elementskey.filter(element => element.props.id == 'firepump-div')[0]}
                {elementskey.filter(element => element.props.id == 'trip-div')[0]}
            </div>
        </div>
    );
};

const InspectionShow = (props) => (
    <Show title={<InspectionTitle />} actions={<InspectionShowActions />} {...props}>
        <SimpleShowLayout className="container p-4">
            <h5 className="font-weight-normal">General</h5>
            <InspectionShowGeneral className="mb-3 mt-2" record={{ ...props }} />

            <h5 className="font-weight-normal">Inspected</h5>

            <InspectionShowElements className="mb-3 mt-0 pt-0" record={{ ...props }} />
        </SimpleShowLayout>
    </Show>
);


export default InspectionShow;