import { Layout } from 'react-admin';
import AppBar from '../components/AppBar';
import Notification from '../components/Notification';

const AdminLayout = props => <Layout
    {...props}
    appBar={AppBar}
    notification={Notification}
/>;

export default AdminLayout;