import React from 'react';
import { required, SelectInput, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import ISConfig from '../config';
import SystemElement from './SystemElement';

const SystemElements = ({ type }) => {

    var elementSystemsAux = []
    for (const key in ISConfig.system_types) {
        if (key == type.toLowerCase()) {
            ISConfig.system_types[key].elements.forEach(element => {
                elementSystemsAux.push(
                    <SystemElement id={element.id} name={(ISConfig.system_elements[element.id] != undefined || ISConfig.system_elements[element.id] != null)? ISConfig.system_elements[element.id].label : 'element not defined' } />
                )
            });
        }
    }

    return (
        <>
            {elementSystemsAux}
        </>
    )
}

class SystemCreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
        };
    }

    handleAvailability(event) {
        this.setState({ type: event.target.value });
    }


    render() {
        var SystemTypeSelectChoicesRequired = [];
        for (const key in ISConfig.system_types) {
            SystemTypeSelectChoicesRequired.push({ id: ISConfig.system_types[key].label, name: ISConfig.system_types[key].label })
        }

        return (
            <>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6} sm={4} className="py-0">
                        <TextInput
                            source="name"
                            label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>}
                            validate={required()}
                            style={{ width: "100%" }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} className="py-0">
                        <TextInput
                            source="code"
                            label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Code</h6>}
                            validate={required()}
                            style={{ width: "100%" }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className="py-0">
                        <SelectInput
                            onChange={this.handleAvailability.bind(this)}
                            label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Type</h6>}
                            source="type"
                            validate={required()}
                            choices={SystemTypeSelectChoicesRequired}
                            style={{ width: "100%" }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} style={{ padding: '5px' }} className="py-0">
                    <SystemElements type={this.state.type} />
                </Grid>
            </>
        )
    }
}

export default SystemCreateForm;