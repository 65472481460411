import React, {cloneElement} from 'react';
import { SimpleShowLayout, ReferenceManyField, Datagrid,TextField, ReferenceField, useShowController } from 'react-admin';

const MyShow = props => {
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        //loaded, // boolean that is false until the record is available
        //loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
        version, // integer used by the refresh feature
    } = useShowController(props);
    return (
        <div>
            <h1>{defaultTitle}</h1>
            {cloneElement(props.children, {
                basePath,
                record,
                resource,
                version,
            })}
        </div>
    );
}


const LocationAlternateShow = ({backAction, ...props}) => {

    return (
        <MyShow {...props}>
            <SimpleShowLayout>
                <ReferenceField label="Customer" source="customer_id" resource="customers" reference="customers"
                                linkType="show">
                    <TextField source="client_code"/>
                </ReferenceField>
                <TextField source="name"/>
                <TextField source="address.Street"/>
                <TextField source="address.City"/>
                <TextField source="address.State"/>
                <TextField source="contact.display_name"/>
                <TextField source="notes"/>
                
                <ReferenceManyField
                    addLabel={false}
                    reference="systems"
                    target="location_id"
                    source="id"
                    sort={{field: 'name', order: 'DESC'}}
                >
                    <Datagrid rowClick="show">
                        <TextField source="name"/>
                        <TextField source="status"/>
                        <TextField source="code"/>
                        <TextField source="type"/>
                        <TextField source="main.last_inspection"/>
                        <TextField source="main.valid_until"/>
                        <TextField source="antifreeze.to_test"/>
                        <TextField source="antifreeze.last_inspection"/>
                        <TextField source="antifreeze.valid_until"/>
                        <TextField source="notes"/>
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </MyShow>
    );
}

export default LocationAlternateShow;