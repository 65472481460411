import * as React from "react";
import PropTypes from 'prop-types';

const CustomTextField = ({ source, record = {} }) => {
    return (
        <>
            <span>{record[source]}</span>
        </>
    );
}

CustomTextField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomTextField;