import SystemList from './SystemList';
import SystemShow from './SystemShow';
import SystemEdit from './SystemEdit';
import Icon from '@material-ui/icons/Widgets';

const ops = {
    list: SystemList,
    show: SystemShow,
    edit: SystemEdit,
    icon: Icon
};

export default ops;