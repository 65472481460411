import React from 'react';
import { Fragment } from 'react';
import { List, Filter, TextInput, BooleanInput, Datagrid, TextField, EmailField, BulkDeleteButton, DeleteButton, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const ActionButtons = ({ basePath, record, resource }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} record={record} />
        <ExportButton />
    </TopToolbar>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false}/>
    </Fragment>
);

const InspectorFilter = (props) => (
    <Filter {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <TextInput  source="display_name" alwaysOn />
        <TextInput  source="first_name"  />
        <TextInput  source="last_name"  />
        <TextInput source="email" />
        <BooleanInput source="active" />
    </Filter>
);

const ActiveTextField = ({ record, source }) => (
    <span className={(record[source])? 'text-success':'text-danger'}>{(record[source])? <CheckIcon/>: <ClearIcon/>}</span>
);

const InspectorList = props => (
    <List {...props} filters={<InspectorFilter />} actions={<ActionButtons />}>
        <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
            <TextField source="first_name" label="Name"/>
            <TextField source="last_name" label="Last Name"/>
            <TextField source="display_name" label="Display Name"/>
            <ActiveTextField source="active" label="Active"/>
            <EmailField source="email" label="Email"/>
            <DeleteButton undoable={true} />
        </Datagrid>
    </List>
);

export default InspectorList;