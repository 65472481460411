import ISConfig from './index';

export const fetchConfig = (qbi, debug, frequencies, frequencies_order, statuses, system_elements, system_types, google_api_key) => {
    ISConfig.qbi = qbi;
    ISConfig.debug = debug;
    ISConfig.frequencies = frequencies;
    ISConfig.frequencies_order = frequencies_order;
    ISConfig.statuses = statuses;
    ISConfig.system_elements = system_elements;
    ISConfig.system_types = system_types;
    ISConfig.googleAPIkey = google_api_key;
}