const ShowFormattedDate = ({ date }) => {
    var dateShow = ''
    if(date != null || date != undefined){
        var d = new Date(date);
        var month = ((d.getMonth()+1) < 10)? '0'+(d.getMonth()+1) : d.getMonth()+1;
        var date = (d.getDate() < 10)? '0'+ d.getDate(): d.getDate();
        dateShow = month +'/'+ date +'/'+ d.getFullYear();
    } else {
        dateShow = <span className="fw-italic text-secondary">Null</span>
    }
    
    return (
        <span className="fw-italic">
            {dateShow}
        </span>
        
    );
}

export default ShowFormattedDate;