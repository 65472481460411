import React, { Fragment } from 'react';
import { List, ReferenceField, Datagrid, TextField, BulkDeleteButton, DeleteButton, useRefresh, usePermissions } from 'react-admin';
import CustomDateField from '../components/CustomDateField';
import CustomInspectedFrequenciesField from '../components/CustomInspectedFrequenciesField';

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false} />
    </Fragment>
);

const InspectionList = props => {
    const toRedirect = useRefresh();
    const p = usePermissions();
    const isAdmin = p.loaded && p.permissions && 'user' in p.permissions && 'admin' in p.permissions && p.permissions.admin
    let inspectionDeleteButton = null
    if (isAdmin) {
        inspectionDeleteButton = (
            <DeleteButton redirect={toRedirect} />
        )
    }

    return (

        <List {...props} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
                <CustomDateField source="date" label="Date" />
                <ReferenceField label="Inspector" source="inspector_id" resource="inspectors" reference="inspectors" link="show">
                    <TextField source="display_name" />
                </ReferenceField>
                <CustomInspectedFrequenciesField label="Inspected" source="inspected" />
                {inspectionDeleteButton}
            </Datagrid>
        </List>
    );
}

export default InspectionList;