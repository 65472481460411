import React, { useState } from 'react';
import { Button, SaveButton, useCreate, useNotify, FormWithRedirect } from 'react-admin';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LocationCreateForm from './LocationCreateForm'

function LocationCreateModal({ record, onChange }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('locations');
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = customer_id =>
        async values => {
            var {
                name,
                notes,
                address,
                contact
            } = values;

            create(
                {
                    payload: { 
                        data: { 
                            "customer_id": customer_id, 
                            "name": name,
                            "status": "Active",
                            "notes": notes,
                            "address": {
                                "street": address.street,
                                "city": address.city,
                                "state": address.state,
                                "zip": address.zip,
                            },
                            "contact": {
                                "display_name": (contact != undefined || contact != null)? contact.display_name: '',
                                "email": (contact != undefined || contact != null)? contact.email: '',
                                "phone": (contact != undefined || contact != null)? contact.phone: '',
                                "notes": (contact != undefined || contact != null)? contact.notes: '',
                            }
                    } 
                }
                },
                {
                    onSuccess: () => {
                        setShowDialog(false);
                        onChange();
                        notify('Location created', 'info', {}, false);
                    },
                    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
                }
            );
        };


    return (
        <>
            <Button className="btn btn-light btn-sm text-danger m-0 p-0 mb-1" style={{fontSize: '0.80em'}} onClick={handleClick} label="Add">
                <AddIcon className="m-0"/>
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create location"
            >
                <DialogTitle>
                    <Grid container direction="row" justify="content-between" className="m-0 p-0">
                        <h4 className="p-0 text-danger" style={{marginLeft: '-8px'}}>Create Location</h4>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <CloseIcon item
                                onClick={handleCloseClick}
                                disabled={loading}
                                style={{ cursor: "pointer", color: "gray" }}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>

                <FormWithRedirect
                    resource="locations"
                    save={handleSubmit(record.id)}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <div>
                            <DialogContent className="m-2 p-2">
                                <LocationCreateForm record={{record}}/>
                            </DialogContent>

                            <DialogActions>
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    justify="start"
                                    alignItems="center"
                                    className="p-2"
                                >
                                    <SaveButton
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                    />
                                </Grid>
                            </DialogActions>
                        </div>
                    )}
                />
            </Dialog>
        </>
    );
}

export default LocationCreateModal;
