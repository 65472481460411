import React from 'react';
import { SimpleForm, TextInput, BooleanInput, required } from 'react-admin';
import { Grid } from '@material-ui/core';
import { CreateDialog } from '@react-admin/ra-form-layout';
import RichTextInput from 'ra-input-rich-text';

export const InspectorCreate = (props) => (
    <CreateDialog {...props} fullWidth maxWidth="md" title={<h4 className="p-0 text-danger" style={{ marginLeft: '-8px' }}>Create Inspector</h4>}>
        <SimpleForm fullWidth initialValues={{ status: "Active", contact: { display_name: '', email: '', phone: '', notes: '' } }} redirect="show">
            <div className="w-100">
                <div className="row row-cols-4 row-cols-lg-4 g-2 g-lg-3 px-2">
                    <div>
                        <TextInput source="display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Display Name</h6>} variant="outlined" />
                    </div>
                    <div>
                        <TextInput source="first_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>First Name</h6>} variant="outlined" />
                    </div>
                    <div>
                        <TextInput source="last_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Name</h6>} variant="outlined" />
                    </div>
                    <div>
                        <h6 className="fw-light text-secondary" style={{ marginBottom: '0px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Active</h6>
                        <BooleanInput source="active" label="" />
                    </div>
                </div>

                <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
                    <div className="rounded" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                        <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes" />
                    </div>
                </div>

                <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                <div className="row row-cols-1 px-2">
                    <div>
                        <TextInput source="email" style={{ width: '37%' }} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" />
                    </div>
                </div>
            </div>
        </SimpleForm>
    </CreateDialog>
);

export default InspectorCreate;