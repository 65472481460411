import React from 'react';
import { Show, ReferenceManyField, Datagrid, EditButton, ReferenceField, TextField, useRefresh, usePermissions, DeleteButton, SimpleShowLayout, RichTextField, Pagination  } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import BackButton from '../components/BackButton';
import ShowFormattedDate from '../components/ShowFormattedDate';
import CustomDateField from '../components/CustomDateField';
import SystemTitle from './SystemTitle';
import InspectionCreateModal from '../inspections/InspectionCreateModal';
import CustomInspectedFrequenciesField from '../components/CustomInspectedFrequenciesField';

const SystemShowActions = (props) => {
    const { basePath } = props;
    return (
        <CardActions>
            <BackButton style={{ marginRight: "auto" }}></BackButton>
            <EditButton basePath={basePath} record={props.data} />
        </CardActions>
    );
};

const SystemShowGeneral = ({ record }) => {
    const { name, status, code, type } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small ">Type</h6>
                <span className="fw-italic">{type}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Name</h6>
                <span className="fw-italic">{name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Code</h6>
                <span className="fw-italic">{code}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Status</h6>
                <span className="fw-italic">{status}</span>
            </div>
        </div>
    );
};

const SystemShowNotes = ({ record }) => {
    const { notes } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Notes</h6>
                <span style={(notes.length > 0)? {}: {fontStyle: 'italic'}} className={(notes.length > 0)? '': 'text-secondary'}>{(notes.length > 0)? <RichTextField source="notes" style={{fontSize: '1em'}} />:'empty'}</span>
            </div>
        </div>

    );
};

const SystemShowElements = ({ record }) => {
    const { elements } = record;

    var elementskey = []
    for (const key in elements) {
        var nameofsystem = '';
        switch (key) {
            case 'main':
                nameofsystem = "Main"
                break;
            case 'antifreeze':
                nameofsystem = "Antifreeze"
                break;
            case 'fire_pump':
                nameofsystem = "Fire Pump"
                break;
            case 'trip':
                nameofsystem = "Trip"
                break;
            default:
                break;
        }

        elementskey.push(<div id={nameofsystem + '-div'} className="mb-2">
            <h5 id="nameofsystem" className="fw-bold">{nameofsystem}</h5>
            <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
                <div className={(elements[key].inspections.monthly != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.monthly != undefined) ? 'Monthly' : ''}</h6>
                    {
                        (elements[key].inspections.monthly != undefined) ?
                            (elements[key].inspections.monthly.last_inspection === undefined && elements[key].inspections.monthly.valid_until === undefined) ?
                                <span className="small text-secondary" style={{ fontStyle: 'italic' }}>not assigned</span>
                                :
                                <span className="small"><ShowFormattedDate date={elements[key].inspections.monthly.last_inspection}/> | <ShowFormattedDate date={elements[key].inspections.monthly.valid_until}/></span>
                            : ''
                    }
                </div>
                <div className={(elements[key].inspections.quarterly != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.quarterly != undefined) ? 'Quarterly' : ''}</h6>
                    {
                        (elements[key].inspections.quarterly != undefined) ?
                            (elements[key].inspections.quarterly.last_inspection === undefined && elements[key].inspections.quarterly.valid_until === undefined) ?
                                <span className="small text-secondary" style={{ fontStyle: 'italic' }}>not assigned</span>
                                :
                                <span className="small"><ShowFormattedDate date={elements[key].inspections.quarterly.last_inspection}/> | <ShowFormattedDate date={elements[key].inspections.quarterly.valid_until}/></span>
                            : ''
                    }
                </div>
                <div className={(elements[key].inspections.biannual != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.biannual != undefined) ? 'Biannual' : ''}</h6>
                    {
                        (elements[key].inspections.biannual != undefined) ?
                            (elements[key].inspections.biannual.last_inspection === undefined && elements[key].inspections.biannual.valid_until === undefined) ?
                                <span className="small text-secondary" style={{ fontStyle: 'italic' }}>not assigned</span>
                                :
                                <span className="small"><ShowFormattedDate date={elements[key].inspections.biannual.last_inspection}/> | <ShowFormattedDate date={elements[key].inspections.biannual.valid_until}/></span>
                            : ''
                    }
                </div>
                <div className={(elements[key].inspections.yearly != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.yearly != undefined) ? 'Annual' : ''}</h6>
                    {
                        (elements[key].inspections.yearly != undefined) ?
                            (elements[key].inspections.yearly.last_inspection === undefined && elements[key].inspections.yearly.valid_until === undefined) ?
                                <span className="small text-secondary" style={{ fontStyle: 'italic' }}>not assigned</span>
                                :
                                <span className="small"><ShowFormattedDate date={elements[key].inspections.yearly.last_inspection}/> | <ShowFormattedDate date={elements[key].inspections.yearly.valid_until}/></span>
                            : ''
                    }
                </div>
                <div className={(elements[key].inspections.fiveyears != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.fiveyears != undefined) ? 'Five Years' : ''}</h6>
                    {
                        (elements[key].inspections.fiveyears != undefined) ?
                            (elements[key].inspections.fiveyears.last_inspection === undefined && elements[key].inspections.fiveyears.valid_until === undefined) ?
                                <span className="small text-secondary" style={{ fontStyle: 'italic' }}>not assigned</span>
                                :
                                <span className="small"><ShowFormattedDate date={elements[key].inspections.fiveyears.last_inspection}/> | <ShowFormattedDate date={elements[key].inspections.fiveyears.valid_until}/></span>
                            : ''
                    }


                </div>
            </div>
        </div>
        );
    }

    return (
        <div>
            <div className="mt-4">
                {elementskey.filter(element => element.props.id == 'Main-div')[0]}
                {elementskey.filter(element => element.props.id == 'Antifreeze-div')[0]}
                {elementskey.filter(element => element.props.id == 'Fire Pump-div')[0]}
                {elementskey.filter(element => element.props.id == 'Trip-div')[0]}
            </div>
        </div>
    );
};

const InspectionSectionTitle = ({ record }) => {
    return (
        <div className="d-flex align-items-center" >
            <h5 className="font-weight-normal">Inspections</h5>
            <InspectionCreateModal className="d-inline-block" record={record} onChange={useRefresh()}/>
        </div>
    )
}

const SystemShow = (props) => {
    const toRedirect = useRefresh();
    const p = usePermissions();
    const isAdmin = p.loaded && p.permissions && 'user' in p.permissions && 'admin' in p.permissions && p.permissions.admin
    let inspectionDeleteButton = null
    if (isAdmin) {
        inspectionDeleteButton = (
            <DeleteButton redirect={toRedirect} />
        )
    }

    return (
        <Show title={<SystemTitle />} actions={<SystemShowActions />} {...props}>
            <SimpleShowLayout className="container p-4">
                <h5 className="font-weight-normal">General</h5>
                <SystemShowGeneral className="mb-3" record={{ ...props }} />
                <SystemShowNotes className="mb-3" record={{ ...props }} />
                <h5 className="font-weight-normal m-0 p-0">Items</h5>
                <SystemShowElements className="mb-3 mt-0 pt-0" record={{ ...props }} />
                <InspectionSectionTitle record={{ ...props }} />

                <ReferenceManyField
                    addLabel={false}
                    reference="inspections"
                    target="system_id"
                    source="id"
                    sort={{ field: 'date', order: 'DESC' }}
                    display="flex" flex="0 0 100%" flexWrap="wrap"
                    pagination={<Pagination />}
                    perPage={10}
                    emptyText="Nothing to show"
                >
                    <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
                        <CustomDateField source="date" label="Date" />
                        <ReferenceField label="Inspector" source="inspector_id" resource="inspectors" reference="inspectors" link="show">
                            <TextField source="display_name" />
                        </ReferenceField>
                        <CustomInspectedFrequenciesField label="Inspected" source="inspected" />
                        {inspectionDeleteButton}
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
}

export default SystemShow;