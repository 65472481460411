import InspectorList from './InspectorList';
import InspectorShow from './InspectorShow';
import InspectorEdit from './InspectorEdit';
import InspectorCreate from './InspectorCreate';
import Icon from '@material-ui/icons/AllOut';

const ops = {
    list: InspectorList,
    show: InspectorShow,
    edit: InspectorEdit,
    create: InspectorCreate,
    icon: Icon
};

export default ops;