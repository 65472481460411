import React from 'react';
import { Show, SimpleShowLayout, EditButton } from 'react-admin';
import BackButton from '../components/BackButton';
import CardActions from "@material-ui/core/CardActions";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import UserTitle from './UserTitle';

const UsersShowActions = ({ basePath, data }) => (
    <CardActions>
        <BackButton style={{marginRight: "auto"}}></BackButton>
        <EditButton basePath={basePath} record={data}/>
    </CardActions>
);

const UserShowGeneral = ({ record }) => {
    const { display_name, disabled } = record;

    return (
        <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2">
            <div>
                <h6 className="fw-light text-secondary small">Username</h6>
                <span className="fw-italic">{display_name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Disabled</h6>
                <span className={(disabled) ? 'text-success' : 'text-danger'}><CircleIcon/></span>
            </div>
        </div>
    );
};

const UserShowContact = ({ record }) => {
    const { email, phone_number } = record;

    return (
        <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2">
            <div>
                <h6 className="fw-light text-secondary small">Email</h6>
                <span className="fw-italic text-lowercase">{email}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Phone</h6>
                <span style={(phone_number.length > 0)? {}: {fontStyle: 'italic'}} className={(phone_number.length > 0)? '': 'text-secondary'}>{(phone_number.length > 0)? phone_number:'empty'}</span>
            </div>
        </div>
    );
};

const UserShowAccess = ({ record }) => {
    const { access } = record;

    return (
        <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2">
            <div>
                <h6 className="fw-light text-secondary small">User</h6>
                <span className={(access.user) ? 'text-success' : 'text-danger'}>{(access.user) ? <CheckIcon /> : <ClearIcon />}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Admin</h6>
                <span className={(access.admin) ? 'text-success' : 'text-danger'}>{(access.admin) ? <CheckIcon /> : <ClearIcon />}</span>
            </div>
        </div>
    );
};

const UserShow = (props) => (
    <Show title={<UserTitle />}  actions={<UsersShowActions/>}  {...props}>
        <SimpleShowLayout className="container p-4">
            <h5 className="font-weight-normal">General</h5>
            <UserShowGeneral className="mb-3" record={{ ...props }} />

            <h5 className="font-weight-normal">Contact</h5>
            <UserShowContact className="mb-3" record={{ ...props }} />

            <h5 className="font-weight-normal">Access</h5>
            <UserShowAccess className="mb-3" record={{ ...props }} />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;