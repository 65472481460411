var ISConfig  = {
    qbi: null,
    debug: null,
    apiURL: process.env.REACT_APP_APIURL,
    googleAPIkey: null,
    frequencies: null,
    frequencies_order: null,
    statuses: null,
    system_elements: null,
    system_types: null
};

export default ISConfig;
