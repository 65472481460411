import * as React from 'react';
import {
    Admin,
    Resource
} from 'react-admin';

import { Route } from 'react-router-dom';

import simpleRestProvider from 'ra-data-simple-rest';

import { FirebaseAuthProvider } from 'react-admin-firebase';
import { firebaseConfig } from './config/firebase';
import firebase from 'firebase';

import { httpClient } from './fireauth/HttpClient';

import ISConfig from './config';
import { fetchConfig } from './config/fetchConfig';

import dashboard from './dashboard';
import customers from './customers';
import locations from './locations';
import LocationAlternateShow from './locations/LocationAlternateShow';
import users from './users';
import inspectors from './inspectors';
import systems from './systems';
import inspections from './inspections';
import ErrorPage from './pages/maintenance/MaintenancePage';
import LoaderPage from './pages/loader/LoaderPage';
import CustomLoginPage from './pages/login/LoginPage';
import AdminLayout from './layouts/AdminLayout'
import LogoutButton from './components/LogoutButton'

import { createMuiTheme } from '@material-ui/core/styles';

const dataProvider = simpleRestProvider(ISConfig.apiURL, httpClient);

const firebaseOptions = { logging: false };

const theme = createMuiTheme({ sidebar: { width: 200, closedWidth: 55 } });

const firebaseAuthProvider = FirebaseAuthProvider(firebaseConfig, firebaseOptions);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isConfigFetched: false
        };
    }

    componentDidMount() {
        fetch(ISConfig.apiURL + '/ping')
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error: true
                    });
                }
            )
    }

    componentDidUpdate(prevProps, prevState) {
        var thisclass = this
        firebase.auth().onAuthStateChanged(function (user) {
            var isConfigFetched = false, error = false;
            if (user) {
                fetch(ISConfig.apiURL + '/config', {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + user._lat,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            fetchConfig(result.qbi,
                                result.debug,
                                result.frequencies,
                                result.frequencies_order,
                                result.statuses,
                                result.system_elements,
                                result.system_types,
                                result.google_api_key);
                            isConfigFetched = true;
                            error = false;
                        },
                        (error) => {
                            isConfigFetched = false;
                            error = true;
                        }
                    )
            } else {
                isConfigFetched = false;
                error = false;
            }

            if (prevState.isConfigFetched !== isConfigFetched) {
                thisclass.setState({ error: error, isConfigFetched: isConfigFetched })
            }
        });
    }

    render() {
        const { error, isLoaded, isConfigFetched } = this.state;
        let customRoutes = [<Route exact path="/locations/:id/showalt" basePath={"http://localhost/3000"} component={LocationAlternateShow} />];
        if (error || (error && !isConfigFetched)) {
            return <ErrorPage />
        } else if (!isLoaded) {
            return <LoaderPage />
        } else {
            return (
                <Admin theme={theme} layout={AdminLayout} logoutButton={LogoutButton} dataProvider={dataProvider} customRoutes={customRoutes} {...dashboard} authProvider={firebaseAuthProvider} loginPage={CustomLoginPage}>
                    {permissions => {

                        let outContainer = [<div></div>]
                        let userActions = []
                        let adminActions = []
                        if (permissions && 'user' in permissions && permissions.user) {
                            userActions = [
                                <Resource name="customers" {...customers} />,
                                <Resource name="locations" {...locations} />,
                                <Resource name="inspections" {...inspections} />
                            ]
                            if (permissions && 'admin' in permissions && permissions.admin) {
                                adminActions = [
                                    <Resource name="users" {...users} />,
                                    <Resource name="inspectors" {...inspectors} />,
                                    <Resource name="systems" {...systems} />
                                ]
                            }
                            outContainer = [...userActions, ...adminActions]
                        }
                        return outContainer
                    }}
                </Admin>
            );
        }
    }
}

export default App;
