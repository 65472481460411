import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, ShowButton } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import ISConfig from '../config';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';
import CustomAddressInput from '../components/CustomAddressInput';

const CustomersEditActions = ({ basePath, data }) => (
    <CardActions>
        <BackButton style={{ marginRight: "auto" }}></BackButton>
        <ShowButton basePath={basePath} record={data} />
    </CardActions>
);

const CustomerEditGeneral = ({ record }) => {
    var CustomerStatusSelectChoices = [];
    ISConfig.statuses.customer.forEach(element => {
        CustomerStatusSelectChoices.push({ id: element, name: element })
    });

    return (
        <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 px-2">
            <div>
                {(ISConfig.qbi) ? <TextInput disabled source="name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} fullWidth variant="outlined" /> : <TextInput source="name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} fullWidth variant="outlined" />}
            </div>
            <div>
                <TextInput source="client_code" className="p-0" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Client Code</h6>} fullWidth variant="outlined" />
            </div>
            <div>
                <SelectInput source="status" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Status</h6>} choices={CustomerStatusSelectChoices} fullWidth variant="outlined"/>
            </div>
        </div>
    );
};

const CustomerEditEmail = ({ record }) => {
    return (
        <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 px-2">
            <div>
                {(ISConfig.qbi) ? <TextInput disabled source="email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} fullWidth variant="outlined" /> : <TextInput source="email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} fullWidth variant="outlined" />}
            </div>
        </div>
    );
};

const CustomerEditNotes = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
            <div className="rounded" style={{border: '1px solid', borderColor: 'rgb(211, 211, 211)'}}>
                <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes"/>
            </div>
        </div>
    );
};

const CustomerEdit = (props) => {
    if (ISConfig.qbi) {
        return (
            <Edit className="m-0 p-0" title="Edit Customer" actions={<CustomersEditActions />} {...props}>
                <SimpleForm className="container mx-0 px-0" redirect="show">
                    <CustomerEditGeneral className="mb-3" record={{ ...props }} />
                    <CustomerEditEmail className="mb-3" record={{ ...props }} />
                    <CustomerEditNotes className="mb-3" record={{ ...props }} />
                </SimpleForm>
            </Edit>
        )
    } else {
        return (
            <Edit className="m-0 p-0" title="Edit Customer" actions={<CustomersEditActions />} {...props} redirect="show">
                <SimpleForm className="container mx-0 px-0" redirect="show">
                    <CustomerEditGeneral className="mb-3" record={{ ...props }} />
                    <CustomerEditEmail className="mb-3" record={{ ...props }} />
                    <CustomerEditNotes className="mb-3" record={{ ...props }} />
                    <h6 className="font-weight-normal px-2 pt-2">Address</h6>
                    <div className="px-2 w-100">
                        <CustomAddressInput record={{ ...props }} />
                    </div>
                </SimpleForm>
            </Edit>
        )
    }
};

export default CustomerEdit;
