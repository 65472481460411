import UserShow from './UserShow';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserIcon from '@material-ui/icons/People';

const ops = {
    create: UserCreate,
    edit: UserEdit,
    list: UserList,
    show: UserShow,
    icon: UserIcon
}

export default ops;
