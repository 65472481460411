import LocationList from './LocationList';
import LocationShow from './LocationShow';
import LocationCreate from './LocationCreate';
import LocationEdit from './LocationEdit';
import Icon from '@material-ui/icons/Apartment';

const ops = {
    list: LocationList,
    show: LocationShow,
    create: LocationCreate,
    edit: LocationEdit,
    icon: Icon
};

export default ops