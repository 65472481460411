import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';

const Dashboard = ({ permissions }) => {
    let outContainer = [
        <Card>
            <div className="d-flex pt-5 pb-5 flex-column justify-content-center">
            <div className="w-100 d-flex justify-content-center">
                <img width="140" height="140" src="./img/credential.png" alt="credential" />
            </div>
                
                <h3 className="text-center mt-2">You do not have access</h3>
                <p className="text-center">
                    Oh! It looks like you don't have permissions.
                </p>
            </div>
        </Card>
    ]

    if (permissions && 'user' in permissions && permissions.user) {
        let userDashboard = [
            <Card>
                <CardHeader title="Welcome to the Inspection Scheduling" />
                <CardContent>Cool charts and other stats here...</CardContent>
            </Card>
        ]
        outContainer = [...userDashboard]
    }
    return outContainer
};

export default Dashboard;