import React from 'react';

const LoaderPage = props => (
    <div className="w-100 bg-danger" style={{ height: '100vh' }}>
        <div className="d-flex justify-content-center" style={{paddingTop: '15%'}}>
            <div className="spinner-grow align-self-center bg-light" style={{width: '15rem', height: '15rem'}}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
);

export default LoaderPage;