import React from 'react';
import { SimpleForm, TextInput, BooleanInput, required } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import UserIcon from '@material-ui/icons/AccountCircle';
import AdminIcon from '@material-ui/icons/VerifiedUser';
import EnableIcon from '@material-ui/icons/CheckCircle';

export const UserCreate = (props) => (
    <CreateDialog {...props} fullWidth maxWidth="md" title={<h4 className="p-0 text-danger" style={{ marginLeft: '-8px' }}>Create User</h4>}>
        <SimpleForm fullWidth initialValues={{ status: "Active", contact: { display_name: '', email: '', phone: '', notes: '' } }} redirect="show">
            <div className="w-100">
                <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2 px-2">
                    <div>
                        <TextInput source="display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Display Name</h6>} variant="outlined" />
                    </div>
                </div>
                <div className="px-2 m-0 mb-3">
                    <div className="rounded p-3 pt-2" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                        <div className="row">
                            <div class="w-25">
                                <h6 className="fw-light text-secondary" style={{ marginBottom: '2px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Access</h6>
                                <BooleanInput source="access.user" label="User" options={{
                                    icon: <UserIcon />,
                                    checkedIcon: <UserIcon />,
                                }} />
                                <BooleanInput source="access.admin" label="Admin" options={{
                                    icon: <AdminIcon />,
                                    checkedIcon: <AdminIcon />,
                                }} />
                            </div>
                            <div class="w-25">
                                <h6 className="fw-light text-secondary" style={{ marginBottom: '2px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Disabled</h6>
                                <BooleanInput source="disabled" label="Disabled" options={{
                                    checkedIcon: <EnableIcon />,
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2 px-2">
                    <div>
                        <TextInput source="email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" />
                    </div>
                    <div>
                        <TextInput source="phone_number" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Phone</h6>} variant="outlined" />
                    </div>
                </div>
            </div>

        </SimpleForm>
    </CreateDialog>
);

export default UserCreate;



