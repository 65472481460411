import React from 'react';
import { Datagrid, Show, TextField, ReferenceManyField, DeleteButton, EditButton, useRefresh, usePermissions, SimpleShowLayout, RichTextField, Pagination } from 'react-admin';
import BackButton from '../components/BackButton';
import CustomerTitle from './CustomerTitle';
import LocationCreateModal from '../locations/LocationCreateModal';
import CardActions from "@material-ui/core/CardActions";
import ISConfig from '../config';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const CustomersShowActions = ({ basePath, data }) => (
    <CardActions>
        <BackButton style={{ marginRight: "auto" }}></BackButton>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

const CustomerShowGeneral = ({ record }) => {
    const { name, status, client_code } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">

            <div>
                <h6 className="fw-light text-secondary small">Name</h6>
                <span className="fw-italic">{name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Client Code</h6>
                <span style={(client_code.length > 0) ? {} : { fontStyle: 'italic' }} className={(client_code.length > 0) ? '' : 'text-secondary'}>{(client_code.length > 0) ? client_code : 'empty'}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Status</h6>
                <span className="fw-italic">{status}</span>
            </div>
        </div>
    );
};

const CustomerShowEmail = ({ record }) => {
    const { email } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Email</h6>
                <span className="fw-italic text-lowercase">{email}</span>
            </div>
        </div>
    );
};

const CustomerShowNotes = ({ record }) => {
    const { notes } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Notes</h6>
                <span style={(notes.length > 0) ? {} : { fontStyle: 'italic' }} className={(notes.length > 0) ? '' : 'small text-secondary'}>{(notes.length > 0) ? <RichTextField source="notes" style={{ fontSize: '1em' }} /> : 'empty'}</span>
            </div>
        </div>

    );
};

const CustomerShowQuickbooks = ({ record }) => {
    const { qb_active, qb_notes, qbid } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Quickbooks Status</h6>
                <span className={(qb_active) ? 'text-success' : 'text-danger'}>{(qb_active) ? <CheckIcon /> : <ClearIcon />}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Quickbooks Notes</h6>
                <span style={(qb_notes.length > 0) ? {} : { fontStyle: 'italic' }} className={(qb_notes.length > 0) ? '' : 'text-secondary'}>{(qb_notes.length > 0) ? <RichTextField source="qb_notes" /> : 'empty'}</span>
            </div>
        </div>
    );
}

const CustomerShowAddress = ({ record }) => {
    const { address } = record;

    return (
        <div>
            <h6 className="fw-light text-secondary small">Address</h6>
            <div>
                <span className="fw-italic">{address.street}</span>
            </div>
            <div className="row my-0 py-0 w-25">
                <div className="col-12">
                    <span className="fw-italic">{address.city} </span>
                    <span className="fw-italic text-uppercase">{address.state} </span>
                    <span className="fw-italic">{address.zip} </span>
                </div>
            </div>
        </div>
    );
};

const CustomerShow = (props) => {
    const toRedirect = useRefresh();

    const p = usePermissions();
    const isAdmin = p.loaded && p.permissions && 'user' in p.permissions && 'admin' in p.permissions && p.permissions.admin
    let locationDeleteButton = null
    if (isAdmin) {
        locationDeleteButton = (
            <DeleteButton redirect={toRedirect} />
        )
    }

    return (
        <Show title={<CustomerTitle />} actions={<CustomersShowActions />}  {...props}>
            <SimpleShowLayout className="container p-4">
                <CustomerShowGeneral className="mb-3" record={{ ...props }} />
                <CustomerShowEmail className="mb-3" record={{ ...props }} />
                <CustomerShowNotes record={{ ...props }} />

                <CustomerShowAddress className="mb-3" record={{ ...props }} />

                {(ISConfig.qbi) ? <CustomerShowQuickbooks className="mb-3" record={{ ...props }} /> : ''}


                <div className="d-flex align-items-center">
                    <h5 className="font-weight-normal">Locations</h5>
                    <LocationCreateModal className="d-inline-block" record={{ ...props }} onChange={useRefresh()} />
                </div>

                <ReferenceManyField
                    addLabel={false}
                    reference="locations"
                    target="customer_id"
                    source="id"
                    sort={{ field: 'name', order: 'DESC' }}
                    display="flex" flex="0 0 100%" flexWrap="wrap"
                    pagination={<Pagination />}
                    perPage={10}
                    emptyText="Nothing to show"
                >
                    <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
                        <TextField source="name" />
                        <TextField source="status" />
                        <TextField source="address.street" label="Street" />
                        <TextField source="address.city" label="City" />
                        <TextField source="address.state" label="State" />
                        <TextField source="address.zip" label="Zip" />
                        <TextField source="contact.display_name" label="Contact" />
                        {locationDeleteButton}
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};

export default CustomerShow;
