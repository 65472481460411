import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    ShowButton,
    ReferenceField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import ISConfig from '../config';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';
import CustomTextField from '../components/CustomTextField';
import CustomAddressInput from '../components/CustomAddressInput';

const useStyles = makeStyles({ inlineBlock: { display: 'inline-flex', marginRight: '5rem' } });

const LocationEditActions = ({ basePath, data }) => (
    <CardActions>
        <BackButton style={{ marginRight: "auto" }}></BackButton>
        <ShowButton basePath={basePath} record={data} />
    </CardActions>
);

const LocationEditGeneral = ({ record }) => {
    var LocationStatusSelectChoices = []
    ISConfig.statuses.location.forEach(element => {
        LocationStatusSelectChoices.push({ id: element, name: element })
    });

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 px-2">
            <div>
                <TextInput source="name" validate={required()} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} variant="outlined" />
            </div>
            <div>
                <SelectInput source="status" validate={required()} choices={LocationStatusSelectChoices} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Status</h6>} variant="outlined"/>
            </div>
            <div>
                <h6 className="fw-light text-secondary" style={{ marginBottom: '2px', fontSize: '11px' }}>Customer</h6>
                <ReferenceField style={{ margin: '0px' }} label="" source="customer_id" resource="customers" reference="customers"><CustomTextField className="m-0 p-0" source="name" /></ReferenceField>
            </div>
        </div>
    );
};

const LocationEditGeneralNotes = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
            <div className="rounded" style={{border: '1px solid', borderColor: 'rgb(211, 211, 211)'}}>
                <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes"/>
            </div>
        </div>
    );
};

const LocationEditContact = ({ record }) => {
    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 px-2">
            <div>
                {/* <h6 className="fw-light text-secondary">Name</h6> */}
                <TextInput source="contact.display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} variant="outlined" />
            </div>
            <div>
                {/* <h6 className="fw-light text-secondary">Phone</h6> */}
                <TextInput source="contact.phone" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Phone</h6>} variant="outlined"/>
            </div>
            <div>
                {/* <h6 className="fw-light text-secondary">Email</h6> */}
                <TextInput source="contact.email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" />
            </div>
        </div>

    );
};

const LocationEditContactNotes = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
            <div className="rounded" style={{border: '1px solid', borderColor: 'rgb(211, 211, 211)'}}>
                <RichTextInput source="contact.notes" label="Notes" stripTags emptyText="Notes"/>
            </div>
        </div>
    );
};

const LocationEdit = (props) => {
    const classes = useStyles();
    return (
        <Edit title="Edit Location" actions={<LocationEditActions />} {...props}>
            <SimpleForm className="container mx-0 px-0" redirect="show">
                <LocationEditGeneral className="mb-3 mt-2" record={{ ...props }} />
                <LocationEditGeneralNotes className="mb-3" record={{ ...props }} />

                <h6 className="font-weight-normal px-2 pt-2">Address</h6>
                <div className="px-2 w-100">
                    <CustomAddressInput record={{ ...props }} />
                </div>

                <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                <LocationEditContact className="mb-3" record={{ ...props }} />
                <LocationEditContactNotes className="mb-3" record={{ ...props }} />
            </SimpleForm>
        </Edit>
    )
};


export default LocationEdit;
