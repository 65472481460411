import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ShowButton } from 'react-admin';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';
import UserIcon from '@material-ui/icons/AccountCircle';
import AdminIcon from '@material-ui/icons/VerifiedUser';
import EnableIcon from '@material-ui/icons/CheckCircle';

const UsersEditActions = ({ basePath, data }) => (
    <CardActions>
        <BackButton style={{ marginRight: "auto" }}></BackButton>
        <ShowButton basePath={basePath} record={data} />
    </CardActions>
);

const UserEditGeneral = ({ record }) => {
    return (
        <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2 px-2">
            <div>
                <TextInput source="display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Display Name</h6>} variant="outlined" />
            </div>
        </div>
    );
};

const UserEditContact = ({ record }) => {
    return (
        <div className="row row-cols-2 row-cols-lg-3 g-5 g-md-2 g-lg-2 px-2">
            <div>
                <TextInput source="email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" />
            </div>
            <div>
                <TextInput source="phone_number" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Phone</h6>} variant="outlined" />
            </div>
        </div>
    );
};

const UserEditAccess = ({ record }) => {
    return (
        <div className="px-2 m-0 mb-3">
            <div className="rounded p-3 pt-2" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                <div className="row">
                    <div class="w-25">
                        <h6 className="fw-light text-secondary" style={{ marginBottom: '2px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Access</h6>
                        <BooleanInput source="access.user" label="User" options={{
                            icon: <UserIcon />,
                            checkedIcon: <UserIcon />,
                        }} />
                        <BooleanInput source="access.admin" label="Admin" options={{
                            icon: <AdminIcon />,
                            checkedIcon: <AdminIcon />,
                        }} />
                    </div>
                    <div class="w-25">
                        <h6 className="fw-light text-secondary" style={{ marginBottom: '2px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Disabled</h6>
                        <BooleanInput source="disabled" label="Disabled" options={{
                            checkedIcon: <EnableIcon />,
                        }} />
                    </div>
                </div>
            </div>
        </div>
    );
};


export const UserEdit = (props) => {
    return (
        <Edit title="Edit User" actions={<UsersEditActions />} {...props}>
            <SimpleForm className="container mx-0 px-0" redirect="show">
                <UserEditGeneral className="mb-3" record={{ ...props }} />
                <UserEditAccess className="mb-3" record={{ ...props }} />

                <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                <UserEditContact className="mb-3" record={{ ...props }} />

            </SimpleForm>
        </Edit>
    )
};

export default UserEdit;