import React from 'react';
import { Grid } from '@material-ui/core';
import ISConfig from '../config';
import CheckIcon from '@material-ui/icons/Check';
import { CheckboxGroupInput } from 'react-admin';

class SystemElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            systemchecked: false
        };
    }

    handleChange = (event) => {
        (event.length >= 1) ? this.setState({ systemchecked: true }) : this.setState({ systemchecked: false });
    };

    render() {

        var SystemFrequenciesSelectChoices = []
        ISConfig.frequencies_order.forEach(fo => {
            SystemFrequenciesSelectChoices.push({ id: fo, name: ISConfig.frequencies[fo].label })
        });

        return (
            <>
                <Grid container direction="row">
                    <div className="col-xs-12 col-md-4 m-0 pt-2">
                        <div className="d-flex align-items-center">
                            <div className="align-self-center" style={{ width: '25px' }}>
                                <h6 className="align-self-center">{(this.state.systemchecked) ? <CheckIcon style={{fontSize: '18px'}} className="text-success"/>: ''}</h6>
                            </div>
                            <div className="align-self-center">
                                <h6 style={{fontSize: '18px'}}>{this.props.name}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col m-0 p-0">
                        <CheckboxGroupInput className="m-0 p-0" onChange={this.handleChange} id={'checkboxaux-' + this.props.id} source={'elements.' + this.props.id + '.inspections'} label="" choices={SystemFrequenciesSelectChoices} optionValue="id" optionText="name" />
                    </div>
                </Grid>
            </>
        )
    }
}

export default SystemElement;
