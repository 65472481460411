import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '#/',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
};


class LoginPage extends React.Component {
    render() {
        let GoogleSignIn = () => <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;

        return (
            <div className="row m-0 p-0">
                <div className="col-xs-12 col-md-4 bg-danger m-0 p-0" style={{ height: '100vh' }}>
                    <h1 className="display-2 text-white p-3 pb-5 m-0 fw-bold" style={{ height: '20%' }}>Inspection Scheduling</h1>
                    <h1 className="text-white p-3 m-0 pt-5 fw-light text-break mt-0 align-self-center" style={{ height: '20%' }}> | Keep your company <span className="fw-bold">safe</span></h1>
                    <div className="w-100 d-flex align-items-end m-0" style={{ height: '59%' }}>
                        <img className="w-100 m-0 p-0" src="https://acbirpichincha.com.ec/imgs/CIUDAD-SILUETA-BLANCA(1).png" alt="City"/>
                    </div>
                </div>
                <div className="col-xs-12 col-md-8 m-0 p-0">
                    <div className="d-flex flex-column row m-0 p-0 w-100">
                        <div className="d-flex justify-content-center pt-5">
                            <h1 className="d-block mt-5">Sign In <span className="text-danger">to Admin</span></h1>
                        </div>
                        <GoogleSignIn className="d-flex justify-content-center" />
                        <div className="d-flex justify-content-center">
                            <img width="300" height="190" src="https://media.istockphoto.com/vectors/construction-worker-engineer-architect-technician-inspector-and-vector-id1206233621?b=1&k=6&m=1206233621&s=612x612&w=0&h=5TaVUWjQpw_sBh6b34qh3OBRUELTzii6N7WuLlzEBG4=" alt="Workpeople"/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <span className="small">&copy; Bianor 2021</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginPage;