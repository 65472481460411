import { CheckboxGroupInput } from 'react-admin';
import ISConfig from '../config';

const SystemItemsForInspectionForm = ({ record }) => {
    var elements = [];
    for (const key in record) {
        elements.push(key);
    }

    var elementcomponents = [];
    elements.forEach(element => {
        var frequenciesperelement = []
        for (const key in record[element].inspections) {
            frequenciesperelement.push({ id: key, name: key.charAt(0).toUpperCase() + key.slice(1), position: ISConfig.frequencies_order.indexOf(key)});
        }

        elementcomponents.push(
            <div id={element+'-item-div'} >
                <h6 className="fw-bold">{(element.charAt(0).toUpperCase() + element.slice(1)).replace('_', ' ')}</h6>
                <div className="col-xs-12 col m-0 p-0">
                    <CheckboxGroupInput className="m-0 p-0" source={'inspected.'+element} label="" choices={frequenciesperelement.sort(function(a, b){return a.position - b.position})} optionValue="id" optionText="name" />
                </div>
            </div>)
    });

    return (
        <div>
            {elementcomponents.filter(element => element.props.id == 'main-item-div')[0]}
            {elementcomponents.filter(element => element.props.id == 'antifreeze-item-div')[0]}
            {elementcomponents.filter(element => element.props.id == 'fire_pump-item-div')[0]}
            {elementcomponents.filter(element => element.props.id == 'trip-item-div')[0]}
        </div>
    )
}

export default SystemItemsForInspectionForm;