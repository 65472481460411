import React from 'react';
import { SimpleForm, TextInput, required, SelectInput } from 'react-admin';
import ISConfig from '../config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RichTextInput from 'ra-input-rich-text';
import { CreateDialog } from '@react-admin/ra-form-layout';
import CustomAddressInput from '../components/CustomAddressInput';

const CustomerCreate = (props) => {
    if (ISConfig.qbi) {
        return (
            <Card>
                <CardContent>It is not possible to create customers with Quickbooks integration active</CardContent>
            </Card>
        )
    } else {
        var CustomerStatusSelectChoices = [];
        ISConfig.statuses.customer.forEach(element => {
            CustomerStatusSelectChoices.push({ id: element, name: element })
        });

        return (
            <CreateDialog {...props} fullWidth maxWidth="md" title={<h4 className="p-0 text-danger" style={{ marginLeft: '-8px' }}>Create Customer</h4>}>
                <SimpleForm fullWidth initialValues={{ status: "Active", contact: { display_name: '', email: '', phone: '', notes: '' } }} redirect="show">
                    <div className="w-100">
                        <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 px-2">
                            <div>
                                <TextInput source="name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} fullWidth variant="outlined" />
                            </div>
                            <div>
                                <TextInput source="client_code" className="p-0" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Client Code</h6>} fullWidth variant="outlined" />
                            </div>
                            <div>
                                <TextInput source="email" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} validate={required()} fullWidth variant="outlined" />
                            </div>
                            <div>
                                <SelectInput source="status" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Status</h6>} choices={CustomerStatusSelectChoices} fullWidth variant="outlined" />
                            </div>
                        </div>

                        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
                            <div className="rounded" style={{ border: '1px solid', borderColor: 'rgb(211, 211, 211)' }}>
                                <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes" />
                            </div>
                        </div>

                        <h6 className="font-weight-normal px-2 pt-2">Address</h6>
                        <div className="px-2">
                            <CustomAddressInput record={{ ...props }} />
                        </div>
                    </div>


                </SimpleForm>
            </CreateDialog>
        )
    }
};

export default CustomerCreate;