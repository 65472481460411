import React from 'react';
import { Fragment } from 'react';
import { List, Filter, Datagrid, TextInput, TextField, ReferenceField, useListContext, TopToolbar, ExportButton, sanitizeListRestProps, DeleteButton, BulkDeleteButton, CreateButton } from 'react-admin';
import { cloneElement } from 'react';

const ActionButtons = ({ basePath, record, resource }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} record={record} />
        <ExportButton />
    </TopToolbar>
);

const LocationFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false} />
    </Fragment>
);

const LocationList = props => {
    return (
        <>
            <List {...props} filters={<LocationFilter />} bulkActionButtons={<BulkActionButtons />} actions={<ActionButtons />}>
                <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
                    <ReferenceField label="Customer" source="customer_id" resource="customers" reference="customers" link="show" >
                        <TextField source="client_code" label="Code" />
                    </ReferenceField>
                    <TextField source="name" label="Name" />
                    <TextField source="status" label="Status" />
                    <TextField source="address.street" label="Street" />
                    <TextField source="address.zip" label="Zip" />
                    <TextField source="address.city" label="City" />
                    <TextField source="address.state" label="State" />
                    <TextField source="contact.display_name" label="Contact" />
                    <DeleteButton undoable={true} />
                </Datagrid>
            </List>
        </>
    )
};

export default LocationList;