import React from 'react';
import { Show, EditButton, ReferenceManyField, Datagrid, TextField, ReferenceField, useRefresh, usePermissions, DeleteButton, SimpleShowLayout, RichTextField, Pagination, Button } from 'react-admin';
import BackButton from '../components/BackButton';
import CustomTextField from '../components/CustomTextField';
import CardActions from "@material-ui/core/CardActions";
import LocationTitle from './LocationTitle';
import SystemCreateModal from '../systems/SystemCreateModal';
import ShowFormattedDate from '../components/ShowFormattedDate';
import CustomDateField from '../components/CustomDateField';
import InspectionCreateModalForList from '../inspections/InspectionCreateModalForList';
import AddIcon from '@material-ui/icons/Add';

const GetLastInspection = (dates) => {
    var datesobject = []
    var now = new Date();
    dates.forEach(element => {
        datesobject.push(new Date(element));
    });

    var last = datesobject.filter(function (d) {
        return d - now < 0;
    });

    if (last[0] == null || last[0] == undefined) {
        return <span className="small text-secondary rounded" style={{ fontStyle: 'italic' }}>None</span>;
    } else {
        return <ShowFormattedDate date={last[0]} />
    }

}

const GetNextDeadline = (dates) => {
    var datesobject = []
    var now = new Date();
    dates.forEach(element => {
        datesobject.push(new Date(element));
    });

    var next = datesobject.filter(function (d) {
        return d - now > 0;
    })

    if (next[0] == null || next[0] == undefined) {
        return <span className="small text-secondary rounded" style={{ fontStyle: 'italic' }}>None</span>;
    } else {
        return <ShowFormattedDate date={next[0]} />
    }

}

const LocationsShowActions = (props) => {
    const { basePath } = props;

    return (
        <CardActions>
            <BackButton style={{ marginRight: "auto" }}></BackButton>
            <EditButton basePath={basePath} record={props.data} />
        </CardActions>
    );
};

const LocationShowGeneral = ({ record }) => {
    const { name, status } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>Name</h6>
                <span className="fw-italic">{name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>Status</h6>
                <span className="fw-italic">{status}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small" style={{ marginBottom: '12px' }}>Customer</h6>
                <ReferenceField style={{ margin: '0px' }} label="" source="customer_id" resource="customers" reference="customers"><CustomTextField className="m-0 p-0" source="name" /></ReferenceField>
            </div>
        </div>
    );
};

const LocationShowGeneralNotes = ({ record }) => {
    const { notes } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Notes</h6>
                <span style={(notes != undefined || notes != null) ? (notes.length > 0) ? {} : { fontStyle: 'italic' } : {}} className={(notes != undefined || notes != null) ? (notes.length > 0) ? '' : 'text-secondary' : {}}>{(notes != undefined || notes != null) ? (notes.length > 0) ? <RichTextField source="notes" style={{ fontSize: '1em' }} /> : 'empty' : 'empty'}</span>
            </div>
        </div>

    );
};

const LocationShowAddress = ({ record }) => {
    const { address } = record;

    return (
        <div>
            <h6 className="fw-light text-secondary small">Address</h6>
            <div>
                <span className="fw-italic">{address.street}</span>
            </div>
            <div className="row my-0 py-0 w-25">
                <div className="col-12">
                    <span className="fw-italic">{address.city} </span>
                    <span className="fw-italic text-uppercase">{address.state} </span>
                    <span className="fw-italic">{address.zip} </span>
                </div>
            </div>
        </div>
    );
};

const LocationShowContact = ({ record }) => {
    const { contact } = record;

    return (
        <div className="row row-cols-2 row-cols-lg-5 g-3 g-lg-2">
            <div>
                <h6 className="fw-light text-secondary small">Name</h6>
                <span style={(contact.display_name.length > 0) ? {} : { fontStyle: 'italic' }} className={(contact.display_name.length > 0) ? '' : 'text-secondary'}>{(contact.display_name.length > 0) ? contact.display_name : 'empty'}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Phone</h6>
                <span style={(contact.phone.length > 0) ? {} : { fontStyle: 'italic' }} className={(contact.phone.length > 0) ? '' : 'text-secondary'}>{(contact.phone.length > 0) ? contact.phone : 'empty'}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Email</h6>
                <span style={(contact.email.length > 0) ? {} : { fontStyle: 'italic' }} className={(contact.email.length > 0) ? '' : 'text-secondary'}>{(contact.email.length > 0) ? contact.email : 'empty'}</span>
            </div>
        </div>
    );
};

const LocationShowContactNotes = ({ record }) => {
    const { contact } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Notes</h6>
                <span style={contact.notes ? (contact.notes.length > 0) ? {} : { fontStyle: 'italic' } : { fontStyle: 'italic' } } className={(contact.notes.length > 0) ? '' : 'text-secondary'}>{contact.notes ? (contact.notes.length > 0) ? <RichTextField source="contact.notes" style={{ fontSize: '1em' }} /> : 'empty' : 'empty'}</span>
            </div>
        </div>

    );
};

const SystemDetails = ({ id, record, resource }) => {
    const { elements } = record;
    var elementsforshow = [];

    for (const key in elements) {
        var nameofsystem = '';
        switch (key) {
            case 'main':
                nameofsystem = "Main";
                break;
            case 'antifreeze':
                nameofsystem = "Antifreeze";
                break;
            case 'fire_pump':
                nameofsystem = "Fire Pump";
                break;
            case 'trip':
                nameofsystem = "Trip";
                break;
            default:
                break;
        }

        var inspections = elements[key].inspections;
        var frequencies = [];
        var lastinspections = [];
        var nextdeadlines = [];

        for (const frequency in inspections) {
            frequencies.push(frequency.charAt(0).toUpperCase() + frequency.slice(1));
            (inspections[frequency].last_inspection != null && inspections[frequency].last_inspection != undefined) ? lastinspections.push(inspections[frequency].last_inspection) : '';
            (inspections[frequency].valid_until != null && inspections[frequency].valid_until != undefined) ? nextdeadlines.push(inspections[frequency].valid_until) : '';
        }

        var frequenciesforshow = '';
        frequencies.forEach(e => {
            frequenciesforshow += e + ' / ';
        });

        var lastinspectionforshow = GetLastInspection(lastinspections.concat(nextdeadlines));
        var nextdeadlineforshow = GetNextDeadline(nextdeadlines);

        elementsforshow.push(
            <tr className="text-center" id={nameofsystem + '-tr'}>
                <td>{nameofsystem}</td>
                <td>{frequenciesforshow.substring(0, frequenciesforshow.length - 3)}</td>
                <td>{lastinspectionforshow}</td>
                <td>{nextdeadlineforshow}</td>
            </tr>
        )
    }

    return (
        <table className="table table-sm mx-2 p-0 table-borderless">
            <thead>
                <tr className="text-center">
                    <th>ELEMENT</th>
                    <th>FREQUENCIES</th>
                    <th>LAST INSPECTION</th>
                    <th>EARLIEST DEADLINE</th>
                </tr>
            </thead>
            {elementsforshow.filter(element => element.props.id == 'Main-tr')[0]}
            {elementsforshow.filter(element => element.props.id == 'Antifreeze-tr')[0]}
            {elementsforshow.filter(element => element.props.id == 'Fire Pump-tr')[0]}
            {elementsforshow.filter(element => element.props.id == 'Trip-tr')[0]}
        </table>
    );
}

const InspectionSectionTitle = ({ record }) => {
    return (
        <div className="d-flex align-items-center" >
            <InspectionCreateModalForList className="d-inline-block" record={record} onChange={useRefresh()} />
        </div>
    )
}

const LocationShow = ({ backAction, ...props }) => {
    const toRedirect = useRefresh();
    const p = usePermissions();
    const isAdmin = p.loaded && p.permissions && 'user' in p.permissions && 'admin' in p.permissions && p.permissions.admin
    let systemDeleteButton = null
    if (isAdmin) {
        systemDeleteButton = (
            <DeleteButton redirect={toRedirect} />
        )
    }

    return (
        <Show title={<LocationTitle />} actions={<LocationsShowActions />} {...props}>
            <SimpleShowLayout className="container p-4">
                <LocationShowGeneral className="mb-3 mt-2" record={{ ...props }} />
                <LocationShowGeneralNotes record={{ ...props }} />

                <LocationShowAddress className="mb-3" record={{ ...props }} />

                <h6 className="font-weight-normal">Contact</h6>
                <LocationShowContact className="mb-3" record={{ ...props }} />
                <LocationShowContactNotes className="mb-3" record={{ ...props }} />

                <div className="d-flex align-items-center">
                    <h5 className="font-weight-normal">Systems</h5>
                    <SystemCreateModal className="d-inline-block" record={{ ...props }} onChange={useRefresh()} />
                </div>

                <ReferenceManyField
                    addLabel={false}
                    reference="systems"
                    target="location_id"
                    source="id"
                    sort={{ field: 'name', order: 'DESC' }}
                    display="flex" flex="0 0 100%" flexWrap="wrap"
                    pagination={<Pagination />}
                    perPage={10}
                    emptyText="Nothing to show"
                >
                    <Datagrid className="table table-sm table-borderless" expand={<SystemDetails />}>
                        <ReferenceField label="Name" source="id" reference="systems" link="show" style={{color: 'black'}}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Status" source="id" reference="systems" link="show" style={{color: 'black'}}>
                            <TextField source="status" />
                        </ReferenceField>
                        <ReferenceField label="Code" source="id" reference="systems" link="show" style={{color: 'black'}}>
                            <TextField source="code" />
                        </ReferenceField>
                        <ReferenceField label="Type" source="id" reference="systems" link="show" style={{color: 'black'}}>
                            <TextField source="type" />
                        </ReferenceField>
                        <CustomDateField source="last_inspection" label="Last Inspection" />
                        <CustomDateField source="next_deadline" label="Next Deadline" />
                        <InspectionSectionTitle record={{ ...props }} />
                        {systemDeleteButton}
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    );
}



export default LocationShow;
