import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, DateInput, ShowButton, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import ISConfig from '../config';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';

const SystemEditActions = props => {
    const { basePath } = props;
    return (
        <CardActions {...props} >
            <BackButton style={{ marginRight: "auto" }} />
            <ShowButton basePath={basePath} record={props.data} />
        </CardActions>
    );
}

const SystemEditGeneral = ({ record }) => {
    var SystemStatusSelectChoices = [];
    ISConfig.statuses.system.forEach(element => {
        SystemStatusSelectChoices.push({ id: element, name: element })
    });

    var SystemTypeSelectChoicesRequired = [];
    for (const key in ISConfig.system_types) {
        SystemTypeSelectChoicesRequired.push({ id: ISConfig.system_types[key].label, name: ISConfig.system_types[key].label })
    }

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 px-2">
            <div>
                <SelectInput source="type" validate={required()} choices={SystemTypeSelectChoicesRequired} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Type</h6>} variant="outlined" />
            </div>
            <div>
                <TextInput source="name" validate={required()} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Name</h6>} variant="outlined"/>
            </div>
            <div>
                <TextInput source="code" validate={required()} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Code</h6>} variant="outlined"/>
            </div>
            <div>
                <SelectInput source="status" validate={required()} choices={SystemStatusSelectChoices} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Status</h6>} variant="outlined"/>
            </div>
        </div>
    );
};

const SystemEditNotes = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
            <div className="rounded" style={{border: '1px solid', borderColor: 'rgb(211, 211, 211)'}}>
                <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes"/>
            </div>
        </div>
    );
};

const SystemEditElements = props => {
    const { elements } = props.record;
    
    var elementskey = []
    for (const key in elements) {
        var nameofsystem = '';
        switch (key) {
            case 'main':
                nameofsystem = "Main"
                break;
            case 'antifreeze':
                nameofsystem = "Antifreeze"
                break;
            case 'fire_pump':
                nameofsystem = "Fire Pump"
                break;
            case 'trip':
                nameofsystem = "Trip"
                break;
            default:
                break;
        }

        elementskey.push(<div id={nameofsystem + '-div'} className="mb-2">
            <h5 id="nameofsystem" className="fw-bold px-2">{nameofsystem}</h5>
            <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 px-2">
                <div className={(elements[key].inspections.monthly != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.monthly != undefined) ? 'Monthly' : ''}</h6>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Inspection</h6>} source={`elements.${key}.inspections.monthly.last_inspection`} variant="outlined"/>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Valid Until</h6>} source={`elements.${key}.inspections.monthly.valid_until`} variant="outlined"/>
                </div>
                <div className={(elements[key].inspections.quarterly != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.quarterly != undefined) ? 'Quarterly' : ''}</h6>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Inspection</h6>} source={`elements.${key}.inspections.quarterly.last_inspection`} variant="outlined"/>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Valid Until</h6>} source={`elements.${key}.inspections.quarterly.valid_until`} variant="outlined"/>
                </div>
                <div className={(elements[key].inspections.annual != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.annual != undefined) ? 'Annual' : ''}</h6>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Inspection</h6>} source={`elements.${key}.inspections.annual.last_inspection`} variant="outlined"/>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Valid Until</h6>} source={`elements.${key}.inspections.annual.valid_until`} variant="outlined"/>
                </div>
                <div className={(elements[key].inspections.biannual != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.biannual != undefined) ? 'Biannual' : ''}</h6>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Inspection</h6>} source={`elements.${key}.inspections.biannual.last_inspection`} variant="outlined"/>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Valid Until</h6>} source={`elements.${key}.inspections.biannual.valid_until`} variant="outlined"/>
                </div>
                <div className={(elements[key].inspections.fiveyears != undefined) ? '' : 'd-none'}>
                    <h6 className="fw-light text-secondary">{(elements[key].inspections.fiveyears != undefined) ? 'Five Years' : ''}</h6>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Inspection</h6>} source={`elements.${key}.inspections.fiveyears.last_inspection`} variant="outlined"/>
                    <DateInput label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Valid Until</h6>} source={`elements.${key}.inspections.fiveyears.valid_until`} variant="outlined"/>
                </div>
            </div>
        </div>
        );
    }

    return (
        <div>
            <div className="mt-4">
                {elementskey.filter(element => element.props.id == 'Main-div')[0]}
                {elementskey.filter(element => element.props.id == 'Antifreeze-div')[0]}
                {elementskey.filter(element => element.props.id == 'Fire Pump-div')[0]}
                {elementskey.filter(element => element.props.id == 'Trip-div')[0]}
            </div>
        </div>
    );
};

const SystemEdit = (props) => {
    return (
        <Edit fullWidth maxWidth="md" className="m-0 p-0" title="Edit System" actions={<SystemEditActions />}  {...props}>
            <SimpleForm className="container mx-0 px-0" redirect="show">
                <SystemEditGeneral className="mb-3" record={{ ...props }} />
                <SystemEditNotes className="mb-3" record={{ ...props }} />

                <h6 className="font-weight-normal px-2 pt-2">Items</h6>
                <SystemEditElements className="mb-3" {...props} />
            </SimpleForm>
        </Edit>
    )
};

export default SystemEdit;