import { useRecordContext } from 'react-admin';

const CustomInspectedFrequenciesField = (props) => {
    const record = useRecordContext(props);
    var il = [];

    for (const key in record.inspected) {
        var fs = '';
        record.inspected[key].forEach(f => {
            fs += f.charAt(0).toUpperCase() + f.slice(1) + ', ';
        });
        fs = fs.substring(0, fs.length - 2);
        il.push(<div><span className="fw-bold">{key.charAt(0).toUpperCase() + key.slice(1)}: </span><span className="text-secondary fst-italic">{fs}</span></div>)
    }

    return <div>{il}</div>;
}

export default CustomInspectedFrequenciesField;