import React from 'react';

const refreshPage = () => {
    window.location.reload();
}

const MaintenancePage = props => (
    <div className="d-flex pt-5 flex-column justify-content-center bg-danger" style={{height: '100vh'}}>
        <img src="./img/maintenance.svg" alt="maintenance" />
        <h3 className="text-white text-center mt-2">We are currently performing maintenance</h3>
        <p className="text-white text-center">
            We&rsquo;re making the system more awesome. <br />
            We&rsquo;ll be back shortly.
        </p>
        <button className="btn btn-light text-danger w-25 align-self-center" to="/" onClick={refreshPage}>
            Restart
        </button>
    </div>
);

export default MaintenancePage;