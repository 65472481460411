import React from 'react';
import { withRouter } from 'react-router';
import { Link, Button } from 'react-admin';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const BackButton = ({ history: { goBack }, children, ...props }) => (
    <Button className="btn btn-light btn-sm text-danger" component={Link} label="BACK" {...props} onClick={goBack}>
        <ArrowBackIosIcon />
    </Button>
);

export default withRouter(BackButton);