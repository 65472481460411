import React from 'react';
import { Show, SimpleShowLayout, EditButton, RichTextField } from 'react-admin';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';
import InspectorTitle from "./InspectorTitle";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const InspectorShowActions = (props) => {
    const { basePath } = props;

    return (
        <CardActions>
            <BackButton style={{ marginRight: "auto" }}></BackButton>
            <EditButton basePath={basePath} record={props.data} />
        </CardActions>
    );
};

const InspectorShowGeneral = ({ record }) => {
    const { first_name, last_name, display_name, active } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small ">Inspector</h6>
                <span className="fw-italic">{display_name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small ">First Name</h6>
                <span className="fw-italic">{first_name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small ">Last Name</h6>
                <span className="fw-italic">{last_name}</span>
            </div>
            <div>
                <h6 className="fw-light text-secondary small">Active</h6>
                <span className={(active) ? 'text-success' : 'text-danger'}>{(active) ? <CheckIcon /> : <ClearIcon />}</span>
            </div>
        </div>
    );
};

const InspectorShowNotes = ({ record }) => {
    const { notes } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Notes</h6>
                <span style={notes ? (notes.length > 0)? {}: {fontStyle: 'italic'} : {fontStyle: 'italic'}} className={notes ? (notes.length > 0)? '': 'text-secondary' : 'text-secondary'}>{(notes.length > 0)? <RichTextField source="notes" style={{fontSize: '1em'}}/>:'empty'}</span>
            </div>
        </div>
    );
};

const InspectorShowContact = ({ record }) => {
    const { email } = record;

    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3">
            <div>
                <h6 className="fw-light text-secondary small">Email</h6>
                <span className="fw-italic text-lowercase">{email}</span>
            </div>
        </div>
    );
};


const InspectorShow = (props) => (
    <Show title={<InspectorTitle />} actions={<InspectorShowActions />} {...props}>
        <SimpleShowLayout className="container p-4">
            <h5 className="font-weight-normal">General</h5>
            <InspectorShowGeneral className="mb-3" record={{ ...props }} />
            <InspectorShowNotes className="mb-3" record={{ ...props }} />

            <h5 className="font-weight-normal">Contact</h5>
            <InspectorShowContact className="mb-3" record={{ ...props }} />
        </SimpleShowLayout>
    </Show>
);


export default InspectorShow;