import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ShowButton } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CardActions from "@material-ui/core/CardActions";
import BackButton from '../components/BackButton';

const InspectorEditActions = (props) => {
    const { basePath } = props;
    return (
        <CardActions>
            <BackButton style={{ marginRight: "auto" }}></BackButton>
            <ShowButton basePath={basePath} record={props.data} />
        </CardActions>
    );
};

const InspectorEditGeneral = ({ record }) => {
    return (
        <div className="row row-cols-3 row-cols-lg-5 g-2 g-lg-3 px-2">
            <div>
                <TextInput source="display_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Display Name</h6>} variant="outlined"/>
            </div>
            <div>
                <TextInput source="first_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>First Name</h6>} variant="outlined"/>
            </div>
            <div>
                <TextInput source="last_name" label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Last Name</h6>} variant="outlined"/>
            </div>
            <div>
                <h6 className="fw-light text-secondary" style={{ marginBottom: '0px', fontSize: '11px', color: 'rgb(211, 211, 211)' }}>Active</h6>
                <BooleanInput source="active" label=""/>
            </div>
        </div>
    );
};

const InspectorEditNotes = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2 py-0 m-0 mb-3">
            <div className="rounded" style={{border: '1px solid', borderColor: 'rgb(211, 211, 211)'}}>
                <RichTextInput source="notes" label="Notes" stripTags emptyText="Notes"/>
            </div>
        </div>
    );
};

const InspectorEditContact = ({ record }) => {
    return (
        <div className="row row-cols-1 px-2">
            <div>
                <TextInput source="email" style={{ width: '37%' }} label={<h6 className="px-2 mb-5 align-self-center small" style={{ backgroundColor: 'white' }}>Email</h6>} variant="outlined" />
            </div>
        </div>
    );
};

const InspectorEdit = (props) => {
    return (
        <Edit title="Edit Inspector" actions={<InspectorEditActions />} {...props}>
            <SimpleForm className="container mx-0 px-0" redirect="show">
                <InspectorEditGeneral className="mb-3" record={{ ...props }} />
                <InspectorEditNotes className="mb-3" record={{ ...props }} />

                <h6 className="font-weight-normal px-2 pt-2">Contact</h6>
                <InspectorEditContact className="mb-3" record={{ ...props }} />
            </SimpleForm>
        </Edit>
    )
};

export default InspectorEdit;