import React from 'react';
import { Fragment } from 'react';
import { List, Filter, Datagrid, TextInput, TextField, ReferenceField, BulkDeleteButton } from 'react-admin';

const SystemFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
    </Filter>
);

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false} />
    </Fragment>
);

const SystemList = props => {
    return (
        <>
            <List {...props} filters={<SystemFilter />} bulkActionButtons={<BulkActionButtons />}>
                <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
                    <ReferenceField label="Location" source="location_id" resource="locations" reference="locations" link="show" >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" label="Name" />
                    <TextField source="status" label="Status" />
                    <TextField source="code" label="Code" />
                    <TextField source="type" label="Type" />
                    <TextField source="notes" label="Notes" />
                </Datagrid>
            </List>
        </>
    )
};

export default SystemList;