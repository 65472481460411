import React from 'react';
import { Fragment } from 'react';
import { Datagrid, List, Filter, TextInput, SelectInput, EmailField, TextField, BulkDeleteButton, DeleteButton, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import ISConfig from '../config';

const ActionButtons = ({ basePath, record, resource }) => (
    <TopToolbar>
        {!ISConfig.qbi ? <CreateButton basePath={basePath} record={record} /> : null}
        <ExportButton />
    </TopToolbar>
);

const CustomerFilter = (props) => {
    var CustomerStatusSelectChoices = [];
    if (ISConfig.statuses != undefined || ISConfig.statuses != null) {
        ISConfig.statuses.customer.forEach(element => {
            CustomerStatusSelectChoices.push({ id: element, name: element })
        });
    }

    return (
        <Filter {...props}>
            <TextInput source="client_code" alwaysOn />
            <TextInput source="name" />
            <SelectInput source="status" choices={CustomerStatusSelectChoices} />
            <TextInput source="email" />
            {ISConfig.qbi ? <TextInput source="qbid" label="QB Id" /> : null}
        </Filter>
    )
};

const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} undoable={false} />
    </Fragment>
);

const CustomerList = props => (
    <List {...props} filters={<CustomerFilter />} filter={{ has_location: true }} bulkActionButtons={<BulkActionButtons />} actions={<ActionButtons />}>
        <Datagrid rowClick="show" className="table table-sm table-hover table-borderless">
            <TextField source="client_code" label="Code" />
            <TextField source="name" label="Name" />
            <TextField source="status" label="Status" />
            <EmailField source="email" label="Email" />
            <DeleteButton undoable={true} />
        </Datagrid>
    </List>
);

export default CustomerList;
